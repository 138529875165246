import appleLogo from '@/public/images/collections/apple_logo.svg';
import appleLogoBlue from '@/public/images/collections/apple_blue.svg';
import samsungLogo from '@/public/images/collections/samsung_logo.svg';
import samsungLogoBlue from '@/public/images/collections/samsung_blue.svg';
import huaweiLogo from '@/public/images/collections/huawei_logo.svg';
import huaweiLogoBlue from '@/public/images/collections/huawei_blue.svg';
import xiaomiLogo from '@/public/images/collections/xiaomi_logo.svg';
import xiaomiLogoBlue from '@/public/images/collections/xiaomi_blue.svg';
import googleLogo from '@/public/images/collections/google_logo.svg';
import googleLogoBlue from '@/public/images/collections/google_blue.svg';
import oppoLogo from '@/public/images/collections/oppo_logo.svg';
import oppoLogoBlue from '@/public/images/collections/oppo_blue.svg';
import oneplusLogo from '@/public/images/collections/oneplus_logo.svg';
import oneplusLogoBlue from '@/public/images/collections/oneplus_blue.svg';
import htcLogo from '@/public/images/collections/htc_logo.svg';
import htcLogoBlue from '@/public/images/collections/htc_blue.svg';
import asusLogo from '@/public/images/collections/asus_logo.svg';
import asusLogoBlue from '@/public/images/collections/asus_blue.svg';
import lgLogo from '@/public/images/collections/lg_logo.svg';
import lgLogoBlue from '@/public/images/collections/lg_blue.svg';
import dellLogo from '@/public/images/collections/dell_logo.svg';
import sonyLogo from '@/public/images/collections/sony_logo.svg';
import sonyLogoBlue from '@/public/images/collections/sony_blue.svg';
import lenovoLogo from '@/public/images/collections/lenovo_logo.svg';
import hpLogo from '@/public/images/collections/hp_logo.svg';
import appleLaptopLogo from '@/public/images/collections/apple_laptop_logo.svg';
import asusLaptopLogo from '@/public/images/collections/asus_laptop_logo.svg';
import huaweiLaptopLogo from '@/public/images/collections/huawei_laptop_logo.svg';
import acerLaptopLogo from '@/public/images/collections/acer_laptop_logo.svg';
import fujitsuLaptopLogo from '@/public/images/collections/fujitsu_laptop_logo.svg';
import microsoftLaptopLogo from '@/public/images/collections/microsoft_laptop_logo.svg';
import msiLaptopLogo from '@/public/images/collections/msi_laptop_logo.svg';
import razerLaptopLogo from '@/public/images/collections/razer_laptop_logo.svg';
import samsungLaptopLogo from '@/public/images/collections/samsung_laptop_logo.svg';
import lgLaptopLogo from '@/public/images/collections/lg_laptop_logo.svg';
import gatewayLogo from '@/public/images/collections/gateway_logo.svg';
import categories from '@/settings/categories';
import settings from '@/settings';

export function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function scrollToEle(selector: string) {
  if (!selector) return;
  const destination = document.querySelector(selector) as HTMLAnchorElement;

  if (!destination) return;
  let scrollTo = destination?.offsetTop;

  scrollTo -= 150;
  window.scrollTo({ top: scrollTo, behavior: 'smooth' });
}

export const MAP_HANDLE_BLUE_LOGO: { [key: string]: string | StaticImageData } =
  {
    huawei: huaweiLogoBlue,
    'huawei-phones': huaweiLogoBlue,
    華為: huaweiLogoBlue,
    oppo: oppoLogoBlue,
    'oppo-phones': oppoLogoBlue,
    'oppo-phone-series': oppoLogoBlue,
    'google-pixel': googleLogoBlue,
    oneplus: oneplusLogoBlue,
    'oneplus-phones': oneplusLogoBlue,
    asus: asusLogoBlue,
    'asus-phone-series': asusLogoBlue,
    'lg-phones': lgLogoBlue,
    htc: htcLogoBlue,
    'htc-phones': htcLogoBlue,
    xiaomi: xiaomiLogoBlue,
    'xiaomi-phones': xiaomiLogoBlue,
    小米: xiaomiLogoBlue,
    'apple-iphone': appleLogoBlue,
    'samsung-phones': samsungLogoBlue,
    'apple-ipad': appleLogoBlue,
    'samsung-tablets': samsungLogoBlue,
    'sony-xperia-phone-series': sonyLogoBlue,
    'apple-watches': appleLogoBlue,
    'samsung-watches': samsungLogoBlue,
    'xiaomi-watches': xiaomiLogoBlue,
    'oppo-watches': oppoLogoBlue,
  };

export const MAP_HANDLE_LOGO: { [key: string]: string | StaticImageData } = {
  huawei: huaweiLogo,
  'huawei-phones': huaweiLogo,
  華為: huaweiLogo,
  oppo: oppoLogo,
  'oppo-phone-series': oppoLogo,
  'oppo-phones': oppoLogo,
  'google-pixel': googleLogo,
  oneplus: oneplusLogo,
  'oneplus-phones': oneplusLogo,
  asus: asusLogo,
  'asus-phone-series': asusLogo,
  'lg-phones': lgLogo,
  htc: htcLogo,
  'htc-phones': htcLogo,
  xiaomi: xiaomiLogo,
  'xiaomi-phones': xiaomiLogo,
  小米: xiaomiLogo,
  'apple-iphone': appleLogo,
  'samsung-phones': samsungLogo,
  'apple-ipad': appleLogo,
  'samsung-tablets': samsungLogo,
  'apple-macbooks': appleLaptopLogo,
  'dell-laptops': dellLogo,
  'asus-laptops': asusLaptopLogo,
  'sony-xperia-phone-series': sonyLogo,
  'lenovo-laptops': lenovoLogo,
  'hp-laptops': hpLogo,
  'huawei-laptops': huaweiLaptopLogo,
  'acer-laptops': acerLaptopLogo,
  'fujitsu-laptops': fujitsuLaptopLogo,
  'microsoft-laptops': microsoftLaptopLogo,
  'msi-laptops': msiLaptopLogo,
  'razer-laptops': razerLaptopLogo,
  'apple-watches': appleLogo,
  'samsung-watches': samsungLogo,
  'xiaomi-watches': xiaomiLogo,
  'oppo-watches': oppoLogo,
  'samsung-laptops': samsungLaptopLogo,
  'lg-laptops': lgLaptopLogo,
  'gateway-laptops': gatewayLogo,
};

export const getPageUrl = (handle: any) => {
  if (handle.includes('reebelos-gift-card')) return `/${handle}`;
  if (
    categories[handle as keyof typeof categories] &&
    categories[handle as keyof typeof categories].stores.includes(
      settings.store,
    )
  )
    return `/category/${handle}`;

  return `/collections/${handle}`;
};
