import Image from 'next/future/image';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import Link from 'next/link';
import { calculateSavings, getOfferUri } from '@/lib/offer';
import { priceFormater } from '@/settings/index';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';
import certifiedAqua from '@/public/icons/product/certifiedAqua.svg';

const Card = ({ product }: { product: SearchOffers }) => {
  const percentageSaving = calculateSavings(
    product.price,
    product.comparePrice,
  );
  const cardClickedProps = {
    'Section Name': `brand name - ${product.brand}`,
    'Item Title': product.title,
    'Item Name': product.title.split(' - ')[0],
    'Item PSKU': product.psku,
    'Item Condition': product.condition,
    'Item Color': product.color,
    'Item Category': product.productType,
    'Item Storage': product?.storage,
    'Item Brand': product.brand,
    'Item Vendor': product.vendor,
    'Item Vendor SKU': product.sku,
    'Item Price': product.price,
    'Item Compare At Price': product.comparePrice,
    'Item Savings': product.comparePrice && `${percentageSaving}%`,
    'Item Stock': product.stock,
  };

  return (
    <Link
      href={getOfferUri({
        tags: product.tags,
        handle: product.handle,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)}
      key={product.sku}
      passHref
    >
      <a
        className="relative flex h-full w-full flex-col justify-start rounded-md border border-gray-200 bg-white px-3 py-2 lg:py-0"
        onClick={() =>
          triggerMixpanelEvent({
            eventName: 'Card Clicked',
            props: cardClickedProps,
            options: { send_immediately: true },
          })
        }
      >
        <div className="mt-3 flex items-center justify-between">
          <div className="flex items-center gap-1.5 rounded-md bg-[#F5FFFF] px-1 py-1.5 text-xs text-[#497B7D]">
            <div className="h-3.5 w-3.5">
              <Image
                alt="Direct from brand"
                src={certifiedAqua}
                className="h-full w-full"
              />
            </div>
            Direct from brand
          </div>
        </div>

        <div className="flex h-full w-full flex-col p-3">
          <div className="mt-2 flex items-center justify-center">
            <div className="h-24 w-24">
              <Image
                width={100}
                height={100}
                alt={product.title}
                src={product.imageSrc}
                className="h-full w-full object-contain"
              />
            </div>
          </div>
          <div className="mt-2 flex flex-1 flex-col justify-between text-left text-sm">
            <div>
              <h2
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="two-line-ellipsis text-sm"
              >
                {product.title}
              </h2>
            </div>
            <div className="mt-2 flex items-center justify-between lg:mb-1">
              <div>
                <div className="text-sm font-extrabold">
                  {priceFormater.long(product.price)}
                </div>
                {product.comparePrice != null && percentageSaving > 3 && (
                  <div className="text-xs text-gray-700/30">
                    new{' '}
                    <span className=" line-through">
                      {priceFormater.long(product.comparePrice)}
                    </span>
                  </div>
                )}
              </div>
              {percentageSaving > 3 && (
                <div className="flex h-6 items-center justify-center rounded-md bg-red px-2.5 text-xs text-white">
                  Save {percentageSaving}%
                </div>
              )}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default Card;
