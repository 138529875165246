import settings from '@/settings';

const defaultCategories = (() => {
  const isAUStore = settings.store === 'reebelo-au';

  return {
    baby: {
      title: 'Baby Gear',
      tagline: isAUStore
        ? 'Certified Preloved and New Baby Gear'
        : 'Certified Preloved & Used Baby Gear',
      stores: ['reebelo-au', 'reebelo-us'],
      subcategories: {
        nursery: {
          title: 'The Nursery Shop',
          description:
            'Professionally cleaned and renewed nursery essentials for your baby at amazing prices.',
        },
        'out-and-about': {
          title: 'Out and About',
          description:
            'High-quality used and certified preowned travel systems, strollers, car seats, wagons, and accessories- to get you around in style & comfort.',
        },
        sleep: {
          title: 'Sleep Essentials',
          description:
            'Make naptime fuss-free with soothing sleep essentials like cozy bassinets and warm blankets.',
        },
        'at-home': {
          title: 'At Home Baby Supplies',
          description:
            'Reinforced comfort & safety for the at-home baby supplies you need round the clock.',
        },
        electronics: {
          title: isAUStore ? 'Baby Monitors' : 'Refurbished Baby Monitors',
          description:
            'Explore a wide selection of certified refurbished baby monitors, quality-checked for your peace of mind.',
        },
        'baby-feeding': {
          title: 'The Feeding Shop',
          description:
            "Cherish family meal times with our large selection of used and open-box bibs, pacifiers, and teethers expertly renewed for your baby's health and safety.",
        },
        mum: {
          title: isAUStore ? 'The Mum Shop' : 'The Mom Shop',
          description:
            'Find quality yet affordable postpartum care and breastfeeding essentials from top brands.',
        },
        clothing: {
          title: isAUStore ? 'Baby Clothing' : 'Preloved Baby Clothing',
          description:
            "Gently used, high-quality baby clothing that doesn't compromise on style and safety.",
        },
      },
      search: {
        filters: {
          categories:
            "Nursery Care|Nursery Changing|Bouncers|Nursery Decor|Play Mats|Rugs|Cots|Baby Travel|Nappy Bags|Car Seat|Prams|Prams Accessories|Baby Carriers|Baby Carriers Accessories|Baby Blankets|Bassinet|Bedding|High Chair|Baby Monitors|Bibs|Pacifiers|Feeding|Food|Teething|Breastfeeding|Pregnancy & Post Partum Care|Baby's Fashion|Body Suits & Rompers",
        },
        sort: 'price',
        order: 'asc',
      },
    },
    'sports-and-fitness': {
      title: 'Sports Equipment',
      tagline: 'Certified Refurbished Sports and Fitness Gear',
      stores: ['reebelo-us', 'reebelo-au'],
      subcategories: {
        'outdoor-recreation': {
          title: 'Outdoor Recreation',
          description: '',
        },
        'e-bikes-and-more': {
          title: 'E-Bikes and More',
          description: '',
        },
        'sports-and-activities': {
          title: 'Sports & Activities',
          description: '',
        },
        'clothing-and-gear': {
          title: 'Clothing and Gear',
          description: '',
        },
        'fitness-gear': {
          title: 'Fitness',
          description: '',
        },
      },
      search: {
        filters: {
          categories:
            'E-scooters|E-skateboards|Electric Bikes|Hoverboard Bundle|Hoverboards|Fitness|Fitness Equipment|Sports & Fitness|Sports & Fitness Bundle|Yoga|Bikes|Binoculars|Golf|Pickleball|Snowboarding|Ski|Tennis|Hiking Clothing|Hiking Shoes|Cycling Clothing|Tactical Clothing|Golf Clothing|Snowboarding Helments|Ski Helments|Ski and Snowboarding Jackets',
        },
        sort: 'price',
        order: 'asc',
      },
    },
    'power-tools': {
      title: 'Power Tools',
      tagline: 'Certified Refurbished & Used Power Tools',
      stores: ['reebelo-us', 'reebelo-au'],
      subcategories: {
        'power-tools': {
          title: 'Power Tools',
          description: '',
        },
        'outdoor-power-equipment': {
          title: 'Outdoor Power Equipment',
          description: '',
        },
        'power-stations': {
          title: 'Power Stations',
          description: '',
        },
        generators: {
          title: 'Generators',
          description: '',
        },
        handtools: {
          title: 'Handtools',
          description: '',
        },
        'workshop-equipment': {
          title: 'Workshop Equipment',
          description: '',
        },
        'batteries-and-chargers': {
          title: 'Batteries & Chargers',
          description: '',
        },
        'power-tools-accessories': {
          title: 'Accessories',
          description: '',
        },
      },
      search: {
        filters: {
          categories:
            'Power Tools|Hardware Tools|Power Saws|Cordless drills|Impact Wrenches|Grinders|Rotary Tools|Impact Drivers|Nail & Staple Guns|Chainsaws|Pressure Washers|Leaf Blowers and Vacuums|String Trimmers|Snow blowers|Hedge Trimmers|Gas Generators|Electric Generators|Flashlights|Furniture Dollies & Castors|Jacks, Stands, & Sawhorses|Tool & Equipment Trolleys|Wet & Dry Vacums|Work Benches|Batteries & Chargers|Drill Sets|Driver Set|Power Stations|Power Station bundles|Power Station Accessories|Cutting Tools|Files|Hammers & Mallets|Clamps & Vises|Saws|Screwdrivers & Nutdrivers',
        },
        sort: 'price',
        order: 'asc',
      },
    },
    'back-to-school': {
      title: 'Back-to-School',
      tagline: 'Sustainable Back-to-School Supplies',
      stores: ['reebelo-us'],
      subcategories: {
        laptops: {
          title: 'Refurbished Laptops',
          description: '',
        },
        tablets: {
          title: 'Refurbished Tablets',
          description: '',
        },
        calculators: {
          title: 'Calculators',
          description: '',
        },
        'dorm-appliances': {
          title: 'Refurbished Dorm Appliances',
          description: '',
        },
        backpacks: {
          title: 'Backpacks',
          description: '',
        },
        audio: {
          title: 'Audio',
          description: '',
        },
      },
      search: {
        filters: {
          categories:
            'Laptops|Tablets|Headphones|Earphones|Bags & Backpacks|Coffee Makers|Blenders',
        },
        sort: 'price',
        order: 'asc',
      },
    },
  };
})();

export default defaultCategories;
