import Image from 'next/future/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { priceFormater } from '@/settings';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';

interface LazyImageProps {
  src: string;
  alt: string;
  fill: boolean;
  className: string;
}

export const LazyImage = ({ src, alt, fill, className }: LazyImageProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Create an IntersectionObserver to check if the image is in the viewport
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the image is intersecting (in the viewport)
          if (entry.isIntersecting) {
            // Set isVisible state to true to trigger image loading
            setIsVisible(true);
            // Stop observing the image after it becomes visible
            observer.unobserve(entry.target);
          }
        });
      },
      // Define the intersection options
      // The root option specifies the element that is used as the viewport for checking intersection. If null, the IntersectionObserver uses the document's viewport.
      // The rootMargin option defines a margin around the root element specified in the root
      // threshold: 0.1 means that the intersection will trigger when the target element becomes at least 10% visible.

      { root: null, rootMargin: '0px', threshold: 0.1 },
    );

    // Check if the imageRef is available. Start observing the image element
    if (imageRef.current) observer.observe(imageRef.current);

    // Cleanup function for when the component unmounts or observer changes
    return () => {
      // Stop observing the image to avoid memory leaks
      if (imageRef.current) observer.unobserve(imageRef.current);
    };
  }, []);

  return (
    <div ref={imageRef}>
      {isVisible ? (
        <Image src={src} alt={alt} fill={fill} className={className} />
      ) : null}
    </div>
  );
};

export default function ProductBoxBig(props: {
  title: string;
  handle: string;
  extraBrandText?: string;
  image: string;
  price: number;
  addShadow?: boolean;
  displayPrice?: boolean;
  sectionName?: string;
  cardIndex: number;
  psku?: string;
}) {
  const {
    title,
    handle,
    addShadow,
    displayPrice,
    sectionName,
    cardIndex,
    psku,
    image,
  } = props;

  const triggerMixpanelClickEvent = () => {
    const mixpanelProps = {
      'Section Type': 'Carousel',
      'Section Name': sectionName,
      'Section Position': 1,
      'Card Type': 'Product',
      'Card Name': title,
      'Card Position': cardIndex + 1,
      'Item Name': title,
      'Item PSKU': psku,
    };

    triggerMixpanelEvent({
      eventName: 'Card Clicked',
      props: mixpanelProps,
    });
  };

  return (
    <div
      key={handle}
      className={`flex h-full w-full flex-col items-center justify-center rounded-xl border bg-white ${
        addShadow ? 'shadow-[0_20px_20px_-12px_rgba(0,0,0,.1)]' : ''
      }`}
    >
      <Link href={`/collections/${handle}`} passHref>
        <a
          className="block w-full px-2 py-4 xs:px-3 sm:py-5"
          onClick={() => triggerMixpanelClickEvent()}
        >
          <div className="relative pb-[75%]">
            <div className="absolute left-0 top-1/2 h-3/4 w-full -translate-y-1/2">
              <LazyImage
                src={image}
                alt={title}
                fill={true}
                className="object-contain"
              />
            </div>
          </div>
          <h3 className="text-center text-xs font-semibold text-gray-700 xxs:text-sm">
            {title}
          </h3>
          {displayPrice && (
            <div className="text-center text-xs text-gray-700">
              From {priceFormater.long(props.price)}
            </div>
          )}
        </a>
      </Link>
    </div>
  );
}
